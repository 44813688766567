<template>
  <div class="text-center">
    <v-overlay :value="overlay" :z-index="300">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: 'LoadingSpinner',
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
