<template>
  <v-app class="main-container">
    <!-- Layout Maintenance -->
    <Maintenance v-if="inMaintenance" />
    <!-- Layout explorer -->
    <div v-else>
      <MainLayout />

      <!--    FOOTER    -->
      <Loading :overlay="loading" />
      <Loading :overlay="loadingMarkers" />
      <v-dialog
        v-model="campusDetailModal"
        transition="dialog-bottom-transition"
        content-class="favorites__dialog"
        max-width="800px"
        min-width="350px"
        @click:outside="closePanels()"
      >
        <CampusDetail
          v-if="campusDetailModal"
          :outside-close="dialogClosed"
          @close="closeCampusDetail"
        />
      </v-dialog>
      <BaseSnackbar />
      <!--</v-layout>-->
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseSnackbar from '@/components/explorer/base/BaseSnackbar.vue';
import Loading from '@/components/explorer/base/Loading.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import CONFIG from '@/config';
import MainLayout from '@/layouts/MainLayout.vue';
import Maintenance from '@/layouts/Maintenance.vue';
import utils from '@/utils/';
// eslint-disable-next-line import/no-cycle
import trackMixPanel from '@/utils/mixpanel';
import { handleRouteQuery } from '@/utils/filters/routes';

export default {
  name: 'MainExplorer',
  metaInfo() {
    return {
      title: `${this.$t(`seo.${CONFIG.tenant}.site_title`)}`,
      // override the parent template and just use the above title only
      titleTemplate: true,
      meta: [],
    };
  },
  components: {
    BaseSnackbar,
    Loading,
    MainLayout,
    CampusDetail,
    Maintenance,
  },
  beforeRouteUpdate(to, from, next) {
    handleRouteQuery(to.query, { store: this.$store, next });
  },
  data() {
    return {
      inMaintenance: CONFIG.maintenance,
      dialogClosed: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'loading/loading',
      loadingMarkers: 'loading/loadingMarkers',
      showCampusDetail: 'institutions/showCampusDetail',
      campusDetail: 'institutions/campusDetails',
    }),
    campusDetailModal: {
      get() {
        return this.showCampusDetail;
      },
      set(newVal) {
        this.$store.commit('institutions/setShowCampusDetail', newVal);
      },
    },
  },
  updated() {
    // eslint-disable-next-line
    this.$nextTick(function () {
      this.dialogClosed = false;
    });
  },
  methods: {
    ...mapActions({
      stopActualVideo: 'options/stopActualVideo',
    }),
    closePanels() {
      utils.resetUrlFromDigitalProfile();
      trackMixPanel('outside_close_school_profile', {
        school_id: this.campusDetail.uuid,
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
        name: this.campusDetail.campus_name,
      });
      this.dialogClosed = true;
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
      this.stopActualVideo();
    },
  },
};
</script>
