<template>
  <v-main class="main-container">
    <v-container fluid class="ma-0 pa-0 fill-height">
      <v-row no-gutters class="main-container__row">
        <v-col
          cols="12"
          :md="showRightCol ? 7 : 12"
          :class="{ 'special-background': backgroundImage }"
        >
          <div
            class="main-layout-container"
            :class="{ 'main-layout-container--mobile': mobile }"
          >
            <!--    SIDE OF THE HEADER    -->
            <MobileExplorerHeader
              v-if="
                mobile && showNavigation && (exitsInfoGuest || !isGuest)
              "
              @toggleMenu="toggleMenu"
            />

            <v-dialog
              v-model="showNavigationBar"
              max-width="150"
              transition="slide-x-transition"
              :content-class="mobile ? 'main-container__row__dialog' : 'main-container__row__dialog--desktop'"
              @click:outside="defaultState"
            >
              <!--  slim side bar hide    -->
              <CustomMenu
                :in-dashboard="false"
                :show-register-modal="showRegisterModal"
                @logout="resetExplorer"
                @closeSession="closeSession"
                @closeMenu="closeMainMenu"
                @set-default="defaultState"
              />
            </v-dialog>
            <CustomMenu
              v-if="showMenu"
              :in-dashboard="false"
              :show-register-modal="showRegisterModal"
              @logout="resetExplorer"
              @closeSession="closeSession"
              @closeMenu="closeMainMenu"
              @set-default="defaultState"
            />
            <!--  ROUTER VIEW    -->
            <div id="body" class="main-layout-container__body fill-width">
              <transition name="fade" mode="out-in">
                <keep-alive include="Explorer">
                  <router-view
                    :key="$route.fullPath"
                    :logout="logout"
                    @logoutSuccess="logoutOk"
                    @signUp="setNavigationBar"
                  />
                </keep-alive>
              </transition>
            </div>
          </div>
        </v-col>
        <div v-show="$vuetify.breakpoint.width > 1500">
          <img
            v-if="showRightCol"
            class="main-layout-image"
            alt="Login Image"
            :src="imageSelection()"
          />
        </div>
      </v-row>
      <v-dialog
        v-model="showLoginDialog"
        content-class="elevation-0"
        overlay-color="transparent"
        max-width="400"
      >
        <WarningModal
          @close="closeWarningModal"
          @go-register="signUp"
          @go-login="signIn"
        />
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmbedded } from '@/utils/embedded';
import WarningModal from '../components/explorer/guest/WarningModal.vue';
import CustomMenu from './CustomMenu.vue';
import MobileExplorerHeader from './MobileExplorerHeader.vue';

export default {
  name: 'MainLayout',
  components: {
    CustomMenu,
    MobileExplorerHeader,
    WarningModal,
  },
  data() {
    return {
      showNavigationBar: false,
      logout: false,
      showLoginDialog: false,
      showRegisterModal: false,
    };
  },
  computed: {
    ...mapGetters({
      exitsInfoGuest: 'authentication/exitsInfoGuest',
      isGuest: 'authentication/isGuest',
    }),
    showMenu() {
      const embedded = isEmbedded();
      const isMobileAndRouteLogin = !this.mobile && this.currentRoute !== 'Login';
      const canShowMenu = isMobileAndRouteLogin && (this.exitsInfoGuest || !this.isGuest) && !embedded;
      return canShowMenu;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showRightCol() {
      const routes = ['User Register'];
      return this.$vuetify.breakpoint.mdAndUp && routes.includes(this.$route.name);
    },
    backgroundImage() {
      return ['Login', 'LoginApp', 'User Register'].includes(this.$route.name);
    },
    showNavigation() {
      const embedded = isEmbedded();
      const hideNavigationMeta = !!this.$route.meta?.hideNavigation;
      return !embedded && !hideNavigationMeta && ![
        'Login',
        'Register Student',
        'User Register',
        'Application',
        'Simulate',
        'Favorites',
      ].includes(this.$route.name);
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    imageSelection() {
      let imageSelected = Math.floor(Math.random() * 4) + 1;
      // eslint-disable-next-line
      imageSelected = imageSelected.toString() + '.jpg';
      // eslint-disable-next-line
      const images = require('@/assets/login-photo-' + imageSelected);
      return images;
    },
    toggleMenu() {
      this.showNavigationBar = !this.showNavigationBar;
    },
    defaultState() {
      this.showRegisterModal = false;
    },
    closeMainMenu() {
      this.showNavigationBar = false;
    },
    closeSession() {
      this.showNavigationBar = false;
      this.logout = true;
    },
    resetExplorer() {
      this.showNavigationBar = false;
      this.logout = true;
    },
    logoutOk() {
      this.logout = false;
    },
    closeWarningModal() {
      this.showLoginDialog = false;
    },
    setNavigationBar() {
      if (this.mobile) {
        this.showNavigationBar = true;
      }
      this.showRegisterModal = false;
      this.signUp();
    },
    signUp() {
      setTimeout(() => {
        this.showRegisterModal = true;
      }, 100);
    },
    signIn() {
      this.showLoginDialog = false;
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
