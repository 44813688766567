<template>
  <section class="maintenance">
    <img src="@/assets/iconsBase/logos/explorer-white.svg" alt="explorer" />
    <div>
      <h1 v-t="'maintenance.title'" class="maintenance__title" />
      <h2 v-t="'maintenance.subTitle'" class="maintenance__subtitle" />
    </div>
    <div class="d-flex flex-row justify-center align-center">
      <span class="mr-4 maintenance__by">Powered By</span>
      <img src="@/assets/iconsBase/logos/tether-white.svg" alt="Tether" />
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'MaintenanceLayout',
  created() {
    this.resetStores();
  },
  methods: {
    ...mapActions({
      resetStores: 'authentication/resetStores',
    }),
  },
};
</script>
